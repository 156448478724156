import {createStore, Reducer as ReduxReducer, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { CODE_UPDATE_reducer, CODE_UPDATE_action } from './actions/CODE_UPDATE';
import { composeWithDevTools } from 'redux-devtools-extension';

export type Reducer<A extends Actions = Actions> = (state: State, action: A) => State;
export type State = {
  code: string
};

const initialState:State = {
  code: (
    typeof localStorage !== "undefined" 
    && localStorage.getItem("code") 
  )|| "// Paste your JSON here"
};

export type Actions = 
  | CODE_UPDATE_action;

const reducer: Reducer = (state, action) => {
  switch(action.type) {
    case "CODE_UPDATE": return CODE_UPDATE_reducer(state, action);
    default: return state;
  }
}

export default createStore(
  reducer as ReduxReducer<State, Actions>, 
  initialState, 
  composeWithDevTools({})(
    applyMiddleware(thunk)
  )
);