import React from 'react';
import { hydrate, render } from "react-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
import state from './state';
import { Provider } from 'react-redux';

const RootApp = ()=>(
  <Provider store={state}>
    <App />
  </Provider>
)

const rootElement = document.getElementById("root");
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(<RootApp />, rootElement);
} else {
  render(<RootApp />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
