import React from "react";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
import { Split, Fixed, Dynamic } from "./Layout/Split";
import Editor from './Modules/Editor';

const GlobalStyles = createGlobalStyle`
  body, #root {
    margin:0;
    padding:0;
    position:relative;
    height:100vh;
    width:100vw;
    overflow:hidden;
    background-color: #1e1e1e;
  }
`

const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-11818094-11"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-11818094-11');
        `}</script>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#1e1e1e" />
        <meta name="robots" content="index, follow" />
        <meta name="description" content="JSON Utility – Parses, formats and prettifys JSON data"/>
        <link rel="canonical" href="https://json.gg/" />
        <title>{"json.gg { Parse | Explore | Aggregate }"}</title>
      </Helmet>
      <Split into="rows">
        <Fixed size="0px">
        </Fixed>
        <Dynamic>
          <Editor />
        </Dynamic>
      </Split>
    </>
  );
}

export default App;