import styled from "styled-components";

export const Split = styled.div<{ into: "rows"|"columns", reverse?: boolean }>`
  display: flex;
  width:100%;
  height:100%;
  flex-grow: 1;
  flex-direction: ${({
    into, 
    reverse
  })=>`${into==="rows"?"column":"row"}${reverse?'-reverse':''}`};
  align-items: stretch;
`;

export const Fixed = styled.div<{ size: string }>`
  flex-basis:${({size})=>size};
  flex-shrink:0;
`;
export const Dynamic = styled.div`
  flex-grow:1
`;