import { Reducer } from '../state';

export type CODE_UPDATE = ( 
  input: { 
    code: string 
  }
)=> { 
  type: "CODE_UPDATE", 
  code: string 
};

export const CODE_UPDATE: CODE_UPDATE = ({ code }) =>{
  return { 
    type: "CODE_UPDATE", 
    code
  }
}

export type CODE_UPDATE_action = ReturnType<CODE_UPDATE>;

export const CODE_UPDATE_reducer:Reducer<CODE_UPDATE_action> = ( 
  state, 
  { code }
) => {
  typeof localStorage !== "undefined" && localStorage.setItem("code", code); 
  return {
    ...state,
    code
  };
}