import React from "react";
import MonacoEditor, { MonacoEditorProps } from "react-monaco-editor";
import monacoEditor from "monaco-editor";
import { connect } from "react-redux";
import { State } from "../state";
import { CODE_UPDATE } from "../actions/CODE_UPDATE";

type OwnProps = {};
type StateProps = {
  code: string;
};
type DispatchProps = {
  CODE_UPDATE: CODE_UPDATE;
};

type Props = OwnProps & StateProps & DispatchProps;

const Editor: React.FC<Props> = ({
  code,
  CODE_UPDATE
})=>{
  const options: MonacoEditorProps["options"] = {
    selectOnLineNumbers: true,
    autoIndent: true,
    formatOnPaste: true,
    scrollBeyondLastLine: false,
    wrappingIndent: "same",
    fontSize: 16,
    matchBrackets: false
  };
  return (
    <MonacoEditor 
      height="100%"
      language="json"
      theme="vs-dark"
      value={code}
      options={options}
      onChange={(newValue, ...test) => {
        CODE_UPDATE({ code: newValue });
      }}
      editorDidMount={(editor) => {
        editor.focus();
        if(monacoEditor) {
          editor.setSelection(new monacoEditor.Range(1,1,999999999,999999999));
        }
        // console.log(editor.getAction("editor.foldLevel2"))
        window.addEventListener("resize", ()=>{
          editor.layout();
        });
      }}
      editorWillMount={(monaco) => {
        monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
          validate: true,
          allowComments: true
        });
      }}
    />
  );

}

export default connect<StateProps, DispatchProps, OwnProps, State>(
  (state) => ({
    code: state.code
  }),
  (dispatch) =>({
    CODE_UPDATE: (...args)=>dispatch(CODE_UPDATE(...args))
  })
)(Editor);